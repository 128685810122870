import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import logo from '../img/aer-logo.png'

const GreenFooterBar = styled.div`
    display: block;
    background-color: #262c25;
    color: white;
    margin: 10px auto;
    padding: 5px 0;
    max-width: 500px;
    min-width: 300px;
    font-size: 25px;
    font-style: italic;
    box-shadow: 0 3px 10px rgba(10,10,10,.7);
    span{
      font-size: 24px;
      color: #f3720a;
      margin: 0 10px;
    }
`

const AddressInfo = styled.div`
    font-size: 24px;
    text-align: center;
    max-width: 1000px;
    min-width: 300px;
    margin: 0 auto 1em;
    font-style: italic;
    span{
      font-size: 20px;
      color: #f3720a;
      margin: 0 10px;
    }
`;

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered">
          <Link to="/"><img
            src={logo}
            alt="AER"
            style={{ width: 'inherit', height: '10em' }}
          /></Link>
          <h3>Advance Environmental Redevelopment, LLC</h3>
          <GreenFooterBar>
            RESEARCH <span>{'\u2022'}</span> REMEDIATE <span>{'\u2022'}</span> REBUILD
          </GreenFooterBar>
          <AddressInfo>
            900 Madison Avenue, Suite 213, Bridgeport CT 06606<br>
            </br> 
            203-333-2767 <span>{'\u2022'}</span> Mobile: 203-414-5980 <span>{'\u2022'}</span> e-mail: <a href="mailto:chris@aerct.com">Chris@aerct.com</a>
          </AddressInfo>
        </div>
      </footer>
    )
  }
}

export default Footer
